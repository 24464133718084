import {useState, useCallback, useEffect} from 'react';
import useAxios from 'axios-hooks';
import axios from 'axios';
import {useNavigate, useLocation, useSearchParams} from 'react-router-dom';

import api from '@api/index';
import {IResponseDto} from '@interfaces/rest/response.dto';
import {IGuestDto, IGuestSaveDto} from '@interfaces/rest/guestData.dto';
import {IMediaDto} from '@interfaces/rest/media.dto';
import {ISurveyAnswer} from '@pages/Photos/components/survey/survey.types';

import {IUserState} from './user.types';

const useUserState = (
  isKiosk?: boolean,
  expressionsAPI?: string,
): IUserState => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);
  const [user, setUser] = useState<IGuestDto | null>(null);
  const [activeBarcode, setActiveBarcode] = useState<string>('');
  const [photos, setPhotos] = useState<IMediaDto[] | null>(null);
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [isInterviewing, setIsInterviewing] = useState<boolean>(false);

  const [{}, getUserData] = useAxios<IResponseDto<IGuestDto>>({
    method: 'get',
  }, {manual: true});

  const [{}, saveUserData] = useAxios<IResponseDto<IGuestSaveDto>>({
    url: api.saveGuest,
    method: 'post',
  }, {manual: true});

  const [{}, getUserPhotos] = useAxios<IResponseDto<IMediaDto[]>>({
    method: 'get',
  }, {manual: true});

  const [{}, shareImage] = useAxios<IResponseDto<string>>({
    method: 'post',
  }, {manual: true});

  const [{}, saveBackground] = useAxios<IResponseDto<null>>({
    method: 'put',
  }, {manual: true});

  const [{}, postSurveyAnswer] = useAxios<IResponseDto<string>>({
    url: api.postSurveyAnswer,
    method: 'post',
  }, {manual: true});

  const getUserPhotosByBarcode = useCallback(async (
    barcode: string,
  ): Promise<void> => {
    try {
      setIsLoadingImages(true);
      const response = await getUserPhotos({
        url: api.guestPhotos(barcode),
      });
      if (response.data.response?.length) {
        setPhotos(response.data.response);
      }
      setIsLoadingImages(false);
    } catch (error) {
      setIsLoadingImages(false);
    }
  }, []);

  const getUserByBarcode = useCallback(async (
    barcode: string,
  ): Promise<void> => {
    setPhotos(null);
    try {
      setIsLoading(true);
      const response = await getUserData({
        url: api.guestInfo(barcode),
      });
      if (!response.data.state && !response.data.response) {
        setIsLoading(false);
        navigate('/login?ticketBarcode=' + barcode);
        return;
      }
      setUser(response.data.response);
      setActiveBarcode(barcode);
      setIsLoading(false);
      getUserPhotosByBarcode(barcode);
      if (!isKiosk) {
        localStorage.setItem('barcode', barcode);
      }
      navigate('/photos');
    } catch (error) {
      setIsLoading(false);
    }
  }, [isKiosk]);

  const saveUser = useCallback(async (
    ticketBarcodes: string,
    email: string,
    zipCode: string,
    captureNewsYN: boolean,
  ) => {
    try {
      setIsLoading(true);
      const response = await saveUserData({
        data: {
          data: {
            email,
            zipCode,
            captureNewsYN,
          },
          ticketBarcodes,
          survey: {},
        },
      });
      if (response.data.response?.guestId) {
        getUserByBarcode(ticketBarcodes);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const resetUser = useCallback(() => {
    setUser(null);
    setPhotos(null);
    setActiveBarcode('');
    setSelectedPhotos([]);
    try {
      localStorage.removeItem('barcode');
    } catch (error) {}
  }, []);

  useEffect(() => {
    const ticketBarcode: string | null = searchParams.get('ticketBarcode');
    const isDirectLogin =
      location.pathname === '/login' &&
      ticketBarcode?.length === 18 &&
      !isNaN(+ticketBarcode);

    if (
      !isKiosk &&
      !location.pathname.includes('direct-view') &&
      !isDirectLogin
    ) {
      try {
        const barcode = localStorage.getItem('barcode');
        if (barcode?.length === 18) {
          getUserByBarcode(barcode);
        }
      } catch (error) {
        localStorage.removeItem('barcode');
      }
    }
  }, [isKiosk]);

  const checkIsShouldSurvey = useCallback(() => {
    if (user?.guestData && !user.guestData.passedSurvey) {
      setIsInterviewing(true);
    }
  }, [user]);

  const handleSurveyClose = useCallback(() => {
    setIsInterviewing(false);
  }, []);

  const shareImageViaEmail = useCallback(async (
    id: string | string[],
    email: string,
  ): Promise<string> => {
    try {
      setIsLoading(true);
      if (Array.isArray(id)) {
        const requests = id.map((mediaId) => axios.post(
          expressionsAPI + api.mediaShare(mediaId),
          {
            emailsList: [email],
          }
        ));
        const response = await axios.all(requests);
        const isError = response.some((res) => !res.data.state);
        if (isError) {
          setIsLoading(false);
          return 'Unexpected error';
        }
        setSelectedPhotos([]);
        setIsLoading(false);
        return '';
      }

      const response = await shareImage({
        url: api.mediaShare(id),
        data: {
          emailsList: [email],
        },
      });
      if (!response.data.state) {
        return response.data.messages.join(', ');
      }
      setIsLoading(false);
      return '';
    } catch (error: any) {
      setIsLoading(false);
      return error?.message || 'Unexpected error';
    }
  }, [expressionsAPI]);

  const saveSiverScreenBg = useCallback(async (
    mediaId: string,
    backgroundId: string,
  ): Promise<boolean> => {
    try {
      setIsLoading(true);
      const response = await saveBackground({
        url: api.mediaLayer(mediaId),
        data: {
          backgroundMediaLayer: backgroundId,
        },
      });
      setIsLoading(false);
      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  }, []);

  const selectPhoto = useCallback((
    id: string,
    isSelecting = true,
  ) => {
    setSelectedPhotos((prevList: string[]) => isSelecting
      ? [...prevList, id]
      : prevList.filter((listItemId: string) => listItemId !== id)
    );
  }, []);

  const onSurveySubmit = useCallback(() => {
    if (user) {
      setUser({
        ...user,
        guestData: {
          ...user.guestData,
          passedSurvey: true,
        },
      });
    }
  }, [user]);

  const handleSurveySubmit = useCallback(async (answersList: ISurveyAnswer): Promise<void> => {
    try {
      setIsLoading(true);
      const questionIds = Object.keys(answersList);
      const answersDto = questionIds.map((questionId: string) => ({
        questionId,
        answer: answersList[questionId],
      }));
      await postSurveyAnswer({
        data: {
          answers: answersDto,
          guestId: user?.guestId,
          ticketBarcode: activeBarcode,
        },
      });
      handleSurveyClose();
      onSurveySubmit();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.guestId, activeBarcode, onSurveySubmit]);

  return {
    isLoading,
    isLoadingImages,
    getUserByBarcode,
    user,
    saveUser,
    resetUser,
    getUserPhotosByBarcode,
    photos,
    shareImageViaEmail,
    saveSiverScreenBg,
    activeBarcode,
    selectedPhotos,
    selectPhoto,
    isInterviewing,
    checkIsShouldSurvey,
    handleSurveyClose,
    handleSurveySubmit,
  };
};

export default useUserState;
